"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.embedDashboard = embedDashboard;
exports.embedQSearchBar = embedQSearchBar;
exports.embedSession = embedSession;

var _EmbeddableObject = _interopRequireDefault(require("./EmbeddableObject"));

var _EmbeddableDashboard = _interopRequireDefault(require("./EmbeddableDashboard"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * Embed a dashboard.
 * @function
 * @name embedDashboard
 * @param {EmbeddingOptions} options - options set by customers to embed the dashboard.
 */
function embedDashboard(options) {
  var dashboard = new _EmbeddableDashboard["default"](options);
  return embedObject(dashboard);
}
/**
 * Embed a session.
 * @function
 * @name embedSession
 * @param {EmbeddingOptions} options - options set by customers to embed the session.
 */


function embedSession(options) {
  var embeddedSession = new _EmbeddableObject["default"](options);
  return embedObject(embeddedSession);
}
/**
 * Embed Q search bar.
 * @function
 * @name embedQSearchBar
 * @param {EmbeddingOptions} options - options set by customers to embed the Q search bar.
 */


function embedQSearchBar(options) {
  var embeddedQSearchBar = new _EmbeddableObject["default"](_objectSpread(_objectSpread({}, options || {}), {}, {
    isQEmbedded: true
  }));
  return embedObject(embeddedQSearchBar);
}

function embedObject(embeddableObject) {
  var container = embeddableObject.getContainer();
  setTimeout(attachToDom.bind(null, embeddableObject.iframe, container), 0);
  return embeddableObject;
}
/**
 * Create a iframe and attach it to parent element.
 * @function
 * @name attachToDom
 * @param {HTMLIFrameElement} iframe
 * @param {string} url - url of the dashboard to embed with parameter values appended.
 * @param {HTMLElement} container - parent html element.
 */


function attachToDom(iframe, container) {
  if (!iframe) {
    throw new Error('iFrame is required');
  }

  if (!container) {
    throw new Error('container of iFrame is required');
  }

  container.appendChild(iframe);
}